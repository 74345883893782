import React, { memo } from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'

import CardDeck from "react-bootstrap/CardDeck"
import RoomCard from '../room-card/RoomCard'

const RoomsCards = memo(({ collection, setError, error }) => {
    return (
        <CardDeck className="mx-0">

            {collection.map((room) => <RoomCard key={shortid.generate()} room={room} setError={setError} error={error} />)}

        </CardDeck>
    )
})

RoomsCards.propTypes = {
    collection: PropTypes.array.isRequired,
    setError: PropTypes.func,
    error: PropTypes.bool
}

export default RoomsCards
