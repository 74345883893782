import styled from '@emotion/styled'

import { Link } from 'gatsby'

import House from '../../images/svg/house.svg'

export const BackHomeContainer = styled(Link)`
    position: fixed;
    top: 5.625rem;
    left: 3.125rem;
    background-color: var(--gold);
    border-radius: 100%;
    width: 3.125rem;
    height: 3.125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.7;
    visibility: visible;
    transition: all 250ms ease-out;

    &.hidden {
        visibility: hidden;
        opacity: 0;
    }

    &:hover {
        opacity: 1;
    }
`

export const HouseIcon = styled(House)`
    width: 1.5rem;

    path {
        fill: white;
    }
`