import styled from "@emotion/styled"
import { css } from "@emotion/react"

const buttonStyle = css`
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 100%;
    text-align: center;
    color: white;
    font-weight: bold;
    background-color: var(--gold);
    position: absolute;
    right: .3125rem;
    top: .5rem;
    cursor: pointer;
`

export const CounterContainer = styled.div`
    position: relative;
`

export const Increase = styled.div`
    ${buttonStyle}
`

export const Decrease = styled.div`
    ${buttonStyle}
    
    right: 2.3125rem;
`