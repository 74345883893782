import React, { memo, useContext } from 'react'

import ReservationContext from '../../contexts/reservation/reservation.context'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import PersonCounter from '../person-counter/PersonCounter'
import AgeOfChildren from '../age-of-children/AgeOfChildren'

import { SmallContainer } from "./personCountStyles"

const PersonCount = memo(() => {
    const { reservationData: { room: { maxPerson }, personCount: { adults, children } } } = useContext(ReservationContext)
    
    return (
        <div>
            <p className="mb-2">személyek (max {maxPerson} fő)</p>

            <Row>
                
                <Col>
                
                    <PersonCounter
                        label="felnőtt"
                        labelClass="non-floating"
                        name="adults"
                        min={1}
                        max={maxPerson - children.length}
                        disabled
                    />

                </Col>

                <Col>
                
                    <PersonCounter
                        label="gyerek"
                        labelClass="non-floating"
                        name="children"
                        min={0}
                        max={maxPerson - adults}
                        disabled
                    />
                
                </Col>

            </Row>

            { children.length > 0 && <AgeOfChildren /> }

            <SmallContainer>* 6 év alatt ingyenes</SmallContainer>
        </div>
    )
})

export default PersonCount
