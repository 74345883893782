import React from 'react'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Cash from '../../images/svg/cash.svg'
import CreditCard from '../../images/svg/credit-card.svg'
import { StaticImage } from 'gatsby-plugin-image'
import { Container } from "./paymentMethodsStyles"

const PaymentMethods = () => {
    return (
        <Container>

            <p className="h5">elfogadott fizetési módok</p>

            <div className="bg-rounded-white">

                <Row className="justify-content-md-center">

                    <Col sm md={3} className="d-flex flex-column align-items-center mb-4">

                        <Cash />

                        <small className="mt-2">készpénz</small>

                    </Col>

                    <Col sm md={3} className="d-flex flex-column align-items-center mb-4">

                        <CreditCard />

                        <small className="mt-2">előreutalás</small>

                    </Col>

                    <Col sm md={3} className="d-flex flex-column align-items-center mb-4">

                        <StaticImage
                            src="../../images/otp-szepkartya.jpg"
                            alt="OTP SZÉP kártya"
                            placeholder="tracedSVG"
                            width={80}
                        />

                        <small className="mt-2">OTP SZÉP kártya<br />(előreutalással)</small>

                    </Col>

                </Row>

                <p className="lead text-center">FIGYELEM!</p>

                <p className="px-5 text-center">Felhívjuk figyelmét, hogy a foglalás elküldése fizetési kötelezettséget nem von maga után.</p>
                <p className="px-5 text-center">Foglalását először jóvá kell hagyjuk. A fizetés helyben, vagy átutalással történik.</p>

            </div>

        </Container>
    )
}

export default PaymentMethods
