import React from 'react'
import PropTypes from 'prop-types'

import { BackHomeContainer, HouseIcon } from "./backHomeStyles";

const BackHome = ({ to }) => {
  return (
    <BackHomeContainer to={to}>

        <HouseIcon />

    </BackHomeContainer>
  )
}

BackHome.propTypes = {
    to: PropTypes.string.isRequired
}

export default BackHome