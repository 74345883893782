import React, { useContext } from 'react'
import shortid from 'shortid'

import ReservationContext from '../../contexts/reservation/reservation.context'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FormControlSelect from '../form-input/FormControlSelect'

const AgeOfChildren = () => {
    const { reservationData: { personCount }, setPersonCount } = useContext(ReservationContext)

    const options = {
        0: '0-6 év',
        1: '6+ év'
    }

    const onChangeHandler = (index, {target: {value}}) => {
        const newChildren = personCount.children.map((num, key) => {
            if (key !== index) return num

            return parseInt(value)
        })

        setPersonCount({ ...personCount, ['children']: newChildren })
    }

    return (
        <Row>

            { personCount.children.map((value, index) => (
                <Col sm={12} md={4} key={shortid.generate()}>

                    <FormControlSelect
                        options={options}
                        defaultValue={value}
                        onChange={(e) => onChangeHandler(index, e)}
                        label={`${index + 1}. gyerek *`}
                        labelClass="non-floating"
                        groupClass="mb-3"
                    />

                </Col>
            )) }

        </Row>
    )
}

export default AgeOfChildren
