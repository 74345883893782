import React from 'react'
import PropTypes from 'prop-types'

import ContactPersonalInfo from '../contact-presonal-info/ContactPersonalInfo'

const ReservationContactInfo = ({ data, changeHandler }) => {
  return (
      <div className="mb-5">

            <p className="h5 mb-3">személyi adatok</p>

            <div className="bg-rounded-white">
                
                <ContactPersonalInfo
                    isReservation
                    address
                    data={data}
                    changeHandler={changeHandler}
                />

            </div>

        </div>
  )
}

ReservationContactInfo.propTypes = {
    changeHandler: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired
}

export default ReservationContactInfo