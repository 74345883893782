import styled from '@emotion/styled'

export const PageTitle1 = styled.h1`
    text-align: center;
    margin-bottom: 1rem;
`

export const PageTitle3 = styled.h3`
    text-align: center;
    margin-bottom: 2.5rem;
    background: var(--gold);
    padding: 1rem;
    color: white;
`