import React, { useContext, useState, useEffect, useCallback } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import apiClient from "../../utils/apiClient";
import isEmpty from 'lodash/isEmpty'
import format from 'date-fns/format'

import ReservationContext from '../../contexts/reservation/reservation.context'

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import Alert from "react-bootstrap/Alert"
import RoomSelection from '../room-selection/RoomSelection'
import ReservationContactInfo from '../reservation-contact-info/ReservationContactInfo'
import DateSelection from '../date-selection/DateSelection'
import PersonCount from '../person-count/PersonCount'
import Note from '../note/Note'
import PriceCalculation from '../price-calculation/PriceCalculation'
import SendButton from '../send-button/SendButton'
import PaymentMethods from '../payment-methods/PaymentMethods'

import { PageTitle1, PageTitle3 } from "./reservationFormStyles"
import { FormCheck } from "../contact-form/contactFormStyles"

const ReservationForm = () => {
    const { reservationData, setBilling, setGdpr, resetData, setLoading } = useContext(ReservationContext)

    const { executeRecaptcha } = useGoogleReCaptcha()

    const [token, setToken] = useState('')
    const [roomError, setRoomError] = useState(false)

    const [validated, setValidated] = useState(false)
    const [notification, setNotification] = useState({
        variant: null,
        message: '',
    })

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) return

        const result = await executeRecaptcha('homepage')

        setToken(result)
    })

    useEffect(() => {
        if (!isEmpty(token)) return

        handleReCaptchaVerify()
    }, [token])

    const closeAlert = () => setNotification({ variant: null, message: '' });

    const handleCheckChange = () => setGdpr(!reservationData.gdpr)

    const handleFormSubmit = (event) => {
        event.preventDefault()
        event.stopPropagation()

        const form = event.currentTarget

        if (!reservationData.room.num || !form.checkValidity()) {
            if (!reservationData.room.num) {
                setRoomError(true)
            }

            setValidated(true)
            return
        }

        sendFormData({ ...reservationData, token })
    }

    const sendFormData = async (data) => {
        setLoading({
            isLoading: true,
            text: 'Foglalásának rögzítése folyamatban van...'
        })

        const newData = {
            ...data,
            date: {
                ...data.date,
                startDate: format(data.date.startDate, 'yyyy-MM-dd'),
                endDate: format(data.date.endDate, 'yyyy-MM-dd')
            }
        }

        try {
            const response = await apiClient.post(`${process.env.API_URL}static/reservation`, newData)
            const { data: { message } } = await response

            setNotification({
                variant: 'success',
                message,
            })

            resetData()

            setValidated(false)

            window.scrollTo(0, 0)
        }
        catch (error) {
            // TODO: hibak reszletezese
            console.log(error.response.data)
            const { status, data: { message } } = await error.response

            setNotification({
                variant: 'danger',
                message: status === 400 ? 'A csillaggal jelölt mezők kitöltése kötelező' : message,
            })

            window.scrollTo(0, 0)
        }

        setLoading({
            isLoading: false,
            text: ''
        })
    }

    return (
        <Container>

            <PageTitle1>Foglalás</PageTitle1>

            <PageTitle3>Söjtöri Öreghegyi Vendégház</PageTitle3>

            <Row>

                {!isEmpty(notification.variant) && (

                    <Col>

                        <Alert variant={notification.variant} onClose={closeAlert} dismissible>{notification.message}</Alert>

                    </Col>

                )}

            </Row>

            <Form noValidate validated={validated} onSubmit={handleFormSubmit}>

                <RoomSelection error={roomError} setError={setRoomError} />

                <ReservationContactInfo data={reservationData.billing} changeHandler={setBilling} />

                <>

                    <p className="h5 mb-3">foglalási adatok</p>

                    <div className="bg-rounded-white mb-3">

                        <Row>

                            <Col sm={12} md={6}>

                                <DateSelection />

                            </Col>

                            <Col sm={12} md={6}>

                                <PersonCount />

                            </Col>

                        </Row>

                    </div>

                    <Note />

                </>

                <div className="bg-rounded-white">

                    <Row>

                        <Col>

                            <Form.Group controlId="gdpr">

                                <FormCheck
                                    id="gdpr"
                                    label={`Az "Adatvédelmi irányelvek" tájékoztatót elolvastam és elfogadom.`}
                                    checked={reservationData.gdpr}
                                    onChange={handleCheckChange}
                                    required
                                    feedback="A kapcsolati űrlap elküldéséhez a tájékoztatót el kell fogadnod."
                                />

                            </Form.Group>

                        </Col>

                    </Row>

                </div>

                <PriceCalculation />

                <SendButton type="submit" />

            </Form>

            <PaymentMethods />

            <Row>
                <Col className="text-center mb-2">
                    <small className="recaptcha-info">
                        Ezt a webhelyet a reCAPTCHA és a Google{' '}
                        <a href="https://policies.google.com/privacy?hl=hu" target="_blank">
                            Adatvédelem
                        </a>{' '}
                        és{' '}
                        <a href="https://policies.google.com/terms?hl=hu" target="_blank">
                            Általános Szerződési Feltételek
                        </a>{' '}
                        védik.
                    </small>
                </Col>
            </Row>

        </Container>
    )
}

export default ReservationForm
