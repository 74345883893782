import React, { memo, useState, useEffect } from 'react'
import PropTypes from "prop-types"

import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const RoomThumbnail = memo(({ thumbnail }) => {
    const [image, setImage] = useState(null)

    useEffect(() => {
        if (!thumbnail) return

        const image = getImage(thumbnail)

        if (!image) return

        setImage(image)
    }, [thumbnail])

    return image && <GatsbyImage image={image} alt="" />
})

RoomThumbnail.propTypes = {
    thumbnail: PropTypes.object.isRequired
}

export default RoomThumbnail
