import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import classnames from 'classnames'

import RoomsCards from '../rooms-cards/RoomsCards'

const RoomSelection = memo(({setError, error}) => {
    const { allDataJson } = useStaticQuery(
        graphql`
            query RoomsQueryForReservation {
                allDataJson {
                    nodes {
                        rooms {
                            id
                            title
                            services
                            thumbnail {
                                childImageSharp {
                                    gatsbyImageData(width: 350, placeholder: TRACED_SVG, formats: [AUTO, WEBP])
                                }
                            }
                            maxPerson
                        }
                    }
                }
            }
        `
    )

    const rooms = allDataJson.nodes[0].rooms

    return (
        <div className="mb-5">
            <p className={ classnames('h5 mb-3', {'text-danger': error}) }>válasszon szobát</p>
            
            <div className="bg-rounded-white">
                
                <RoomsCards collection={rooms} error={error} setError={setError} />

            </div>

        </div>
    )
})

RoomSelection.propTypes = {
    setError: PropTypes.func,
    error: PropTypes.bool
}

export default RoomSelection
