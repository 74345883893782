import React, { useState, useEffect } from "react"
import apiClient from '../utils/apiClient'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { getDatesBetween } from '../utils/helper'
import isEmpty from 'lodash/isEmpty'
import union from 'lodash/union'

import Seo from '../components/seo/Seo'

import ReservationContext, { initData, initLoading } from '../contexts/reservation/reservation.context'

import ReservationForm from "../components/reservation-form/ReservationForm"
import Footer from '../components/footer/Footer'
import BackToTop from '../components/back-to-top/BackToTop'
import BackHome from '../components/back-home/BackHome'
import LoadingScreen from '../components/loading-screen/LoadingScreen'

import '../scss/app.scss'

const Reservation = () => {
    const [reservationData, setReservationData] = useState(initData)
    const [reservedDates, setReservedDates] = useState([])
    const [loading, setLoading] = useState(initLoading)

    useEffect(async () => {
        await apiClient.get(`${process.env.API_DOMAIN_URL}sanctum/csrf-cookie`)
    }, [])

    useEffect(() => {
        if (reservationData.room.num === 0) return

        getReservedDates(reservationData.room.num)
    }, [reservationData.room.num])

    useEffect(() => {
        switch (loading.isLoading) {
            case true:
                document.body.style['overflow-y'] = 'hidden';
                break;

            default:
                document.body.style['overflow-y'] = 'unset';
                break;
        }
    }, [loading.isLoading])

    const getReservedDates = async (room) => {
        setLoading({
            isLoading: true,
            text: 'Elérhető időpontok frissítése!'
        })
        setReservedDates([])

        try {
            const response = await apiClient.get(`${process.env.API_URL}static/reservation/${room}`)
            const { data: { dates } } = await response

            if (!isEmpty(dates)) {
                let reservedDates = []

                await dates.map((dateInterval) => {
                    const newDates = getDatesBetween(dateInterval[0], dateInterval[1])
                    // TODO: unique dates???
                    reservedDates = union(reservedDates, newDates)
                })

                setReservedDates(reservedDates)
            }
        }
        catch (error) {
            const { data: { message } } = await error.response

            console.error(message)
        }

        setLoading({
            isLoading: false,
            text: ''
        })
    }

    const setRoom = (room) => setReservationData({ ...reservationData, room })
    
    const setBilling = ({ target: { name, value } }) => setReservationData({ ...reservationData, billing: { ...reservationData.billing, [name]: value } })

    const setDate = (date) => setReservationData({ ...reservationData, date })

    const setPersonCount = (personCount) => setReservationData({ ...reservationData, personCount })

    const setNote = (note) => setReservationData({ ...reservationData, note })

    const setGdpr = (gdpr) => setReservationData({ ...reservationData, gdpr })

    const resetData = () => setReservationData({ ...reservationData, ...initData, personCount: { adults: 1, children: [] } })

    const providedData = {
        reservationData,
        reservedDates,
        loading,
        setRoom,
        setBilling,
        setDate,
        setPersonCount,
        setNote,
        setGdpr,
        resetData,
        setLoading
    }

    // TODO: minden input re-rendereli az oldalt!!

    return (
        <>

            <div id="wrapper">

                <Seo title="Foglalás | Söjtöri Öreghegyi Vendégház" />

                <main className="reservation">

                    <GoogleReCaptchaProvider
                        reCaptchaKey={process.env.GOOGLE_RECAPTCHA_API_KEY}
                        language="hu"
                        scriptProps={{
                            async: true,
                            defer: true,
                            appendTo: 'head',
                        }}
                    >

                        <ReservationContext.Provider value={providedData}>

                            <ReservationForm />

                        </ReservationContext.Provider>

                    </GoogleReCaptchaProvider>

                </main>

                <Footer />

                <BackToTop to="/foglalas" />

                <BackHome to="/" />

            </div>

            {loading.isLoading && <LoadingScreen text={loading.text} />}

        </>
    )
}

export default Reservation
