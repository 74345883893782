import React from 'react'
import PropTypes from 'prop-types'
import {format} from 'date-fns'
import {hu} from 'date-fns/locale'

import { FormController } from '../form-input/formInputStyles'

const DateLabel = ({ label, name, value }) => (
    <div className="input-box">
        
        <label className="mr-2">{label}</label>

        <FormController
            id={name}
            readOnly
            {...{name}}
            value={ value ? format(value, "yyyy. MMMM d.", {locale: hu, awareOfUnicodeTokens: true}) : "Nincs megadva" }
        />
    
    </div>
)

DateLabel.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.instanceOf(Date).isRequired
}

export default DateLabel