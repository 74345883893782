import styled from '@emotion/styled'

export const Button = styled.button`
    padding: .625rem 1rem;
    border-radius: 1rem;
    border: .3125rem solid var(--gold);
    background-color: transparent;
    font-size: 2rem;
    transition: all 250ms ease-in-out;
    cursor: pointer;

    &:hover {
        background-color: var(--gold);
        border-color: transparent;
        color: white;
    }
`