import React from 'react'
import PropTypes from 'prop-types'

import { Button } from './sendButtonStyles'

const SendButton = ({ type }) => {
  return (
    <div className="d-flex justify-content-center mb-5">
        <Button type={type}>Küldés</Button>
    </div>
  )
}

SendButton.propTypes = {
    type: PropTypes.string.isRequired
}

export default SendButton