import React, { memo, useContext, useState, useEffect } from 'react'
import { TOMORROW } from '../../utils/helper'

import { DateRangePicker  } from "react-date-range"
import { hu } from "date-fns/locale"

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import ReservationContext from '../../contexts/reservation/reservation.context'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import DateLabel from '../date-label/DateLabel'

const DateSelection = memo(() => {
    const { reservationData: { date }, setDate, reservedDates } = useContext(ReservationContext)

    const [dateRange, setDateRange] = useState(date)

    useEffect(() => {
        setDateRange(date)
    }, [date])

    const handleSelect = ({ selection }) => setDate(selection)

    return (
        <div>

            <Row>

                <Col>

                    <DateLabel
                        label="érkezés"
                        name="from"
                        value={dateRange.startDate}
                    />

                </Col>

                <Col>

                    <DateLabel
                        label="távozás"
                        name="to"
                        value={dateRange.endDate}
                    />

                </Col>
            
            </Row>

            <DateRangePicker
                rangeColors={['#c9a96a']}
                ranges={[dateRange]}
                onChange={handleSelect}
                locale={hu}
                dateDisplayFormat="yyyy. MMMM d."
                minDate={TOMORROW}
                renderStaticRangeLabel={() => {}}
                staticRanges={[]}
                inputRanges={[]}
                showDateDisplay={false}
                weekStartsOn={1}
                disabledDates={reservedDates}
            />
        </div>
    )
})

export default DateSelection
