import styled from '@emotion/styled'

import Card from "react-bootstrap/Card"
import ServiceIcons from '../service-icons/ServiceIcons'

export const CardContainer = styled(Card)`
    border-radius: 0;
    border: 0.37rem solid lightgray;
    transition: all 250ms ease-in-out;
    cursor: pointer;

    &.selected {
        border-color: var(--gold);
    }
`

export const ServiceIconsContainer = styled(ServiceIcons)`
    margin-bottom: 1rem;
    padding: 0;

    svg {
        height: 1.5rem;
    }
`