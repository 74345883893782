import React, { memo, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import ReservationContext from '../../contexts/reservation/reservation.context'

import RoomThumbnail from '../room-thumbnail/RoomThumbnail'
import Card from "react-bootstrap/Card"

import { CardContainer, ServiceIconsContainer } from './roomCardStyles'

const RoomCard = memo(({ room: { id, title, thumbnail, services, maxPerson }, setError, error }) => {
    const { reservationData: { room: { num } }, setRoom } = useContext(ReservationContext)

    const onClickHandler = () => {
        setRoom({num: id, maxPerson})

        if (error)
        {
            setError(false)
        }
    }
    
    return (
        <CardContainer className={classnames({'selected': id === num})} onClick={onClickHandler}>

            <Card.Body className="text-center p-0">

                <RoomThumbnail thumbnail={thumbnail} />

                <Card.Title className="my-3">{ title }</Card.Title>

                <ServiceIconsContainer options={services} />

            </Card.Body>

        </CardContainer>
    )
})

RoomCard.propTypes = {
    room: PropTypes.object.isRequired,
    setError: PropTypes.func,
    error: PropTypes.bool
}

export default RoomCard
