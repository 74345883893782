import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import ReservationContext from '../../contexts/reservation/reservation.context'

import FormInput from '../form-input/FormInput'
import { CounterContainer, Increase, Decrease } from "./personCounterStyles"

const PersonCounter = ({ min, max, name, ...props }) => {
    const { reservationData: { personCount, room: { num } }, setPersonCount } = useContext(ReservationContext)

    const [counter, setCounter] = useState(min)
    const [increaseDisabled, setIncreaseDisabled] = useState(false)
    const [descreaseDisabled, setDecreaseDisabled] = useState(false)

    useEffect(() => {
        if (num !== 0) return

        const newCounter = typeof personCount[name] === 'object' ? personCount[name].length : personCount[name]

        setCounter(newCounter)
    }, [num])

    useEffect(() => {
        if (counter === min) setDecreaseDisabled(true)

        if (counter === max) setIncreaseDisabled(true)

        if (counter > min && counter < max)
        {
            setDecreaseDisabled(false)
            setIncreaseDisabled(false)
        }

        if (name === 'children') return null

        setPersonCount({ ...personCount, [name]: counter })
    }, [counter])

    const increaseHandler = () => {
        const newCount = counter + 1

        if (newCount > max) return null

        setCounter(newCount)

        if (name === 'children')
        {
            personCount[name].push(1)

            setPersonCount({ ...personCount, [name]: personCount[name] })
        }
    }

    const decreaseHandler = () => {
        const newCount = counter - 1

        if (newCount < min) return null

        setCounter(newCount)

        if (name === 'children')
        {
            personCount[name].pop()

            setPersonCount({ ...personCount, [name]: personCount[name] })
        }
    }

    return (
        <CounterContainer>
            <FormInput groupClass="mb-3" {...props} name={name} value={counter} onChange={() => {}} />

            <Decrease className="decreas" onClick={decreaseHandler} disabled={descreaseDisabled}>-</Decrease>

            <Increase className="increase" onClick={increaseHandler} disabled={increaseDisabled}>+</Increase>
        </CounterContainer>
    )
}

PersonCounter.propTypes = {
    name: PropTypes.string.isRequired,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
}

export default PersonCounter
