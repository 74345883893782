import React, { memo, useContext } from 'react'

import ReservationContext from '../../contexts/reservation/reservation.context'

import FormInput from '../form-input/FormInput'

const Note = memo(() => {
    const { reservationData: { note }, setNote } = useContext(ReservationContext)

    const onChangeHandler = ({target: {value}}) => setNote(value)

    return (
        <div className="bg-rounded-white">

            <FormInput
                as="textarea"
                rows={4}
                label="megjegyzés"
                labelClass="non-floating"
                placeholder="pl.: speciális kívánság, egyéb kérdés"
                name="message"
                value={note}
                onChange={onChangeHandler}
                groupClass="mb-0"
            />

        </div>
    )
})

export default Note
