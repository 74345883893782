import React, { memo, useContext, useState, useEffect } from 'react'
import differenceInDays from 'date-fns/differenceInDays'
import { moneyFormatter } from "../../utils/helper"
import apiClient from '../../utils/apiClient'
import isEmpty from 'lodash/isEmpty'

import ReservationContext from '../../contexts/reservation/reservation.context'

import { PriceContainer, Price } from "./priceCalculationStyles"

const PriceCalculation = memo(() => {
    const { reservationData: { date, personCount } } = useContext(ReservationContext)

    const [total, setTotal] = useState(0)
    const [prices, setPrices] = useState([])

    useEffect(async () => {
        try {
            const response = await apiClient.get(`${process.env.API_URL}prices`)
            const { data } = await response

            setPrices(data)
        }
        catch (error) {
            const { data: { message } } = await error.response

            console.log(message)
        }
    }, [])

    useEffect(() => {
        if (isEmpty(prices)) return

        const { startDate, endDate } = date
        const { adults, children } = personCount

        const days = differenceInDays(endDate, startDate) || 1

        const childrenCount = children.filter((isFree) => isFree !== 0).length
        const totalPerson = adults + childrenCount

        const basePrice = prices.filter(({ person }) => person === totalPerson)[0]
        const newTotal = basePrice.huf * days;

        setTotal(moneyFormatter(newTotal))
    }, [date, personCount])

    return (
        <div className="d-flex align-items-center justify-content-center my-5">
            <PriceContainer>
                Fizetendő: <Price>{total}</Price>
            </PriceContainer>
        </div>
    )
})

export default PriceCalculation
