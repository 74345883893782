import styled from '@emotion/styled'

export const PriceContainer = styled.span`
    display: inline-block;
    background-color: var(--gold);
    padding: .625rem;
    border-radius: .5rem;
    font-size: 1.5rem;
    font-weight: bold;
`
export const Price = styled.span`
    font-size: 2rem;
    margin-left: 1rem;
    color: white;
`